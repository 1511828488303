/* Import Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
    margin: 0;
    font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
    background-color: #fff;
}
  

.search-container {
    width: 100%;
    background-color: #F8F1FF;
}
  
.search-bar {
    display: flex;
    align-items: center;
    background-color: #F8F1FF;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  
.back-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
}
  
.search-input {
    flex: 1;
    padding: 10px;
    background-color: #F8F1FF;
    border: 1px solid #F8F1FF;
    border-radius: 4px;
    font-size: 16px;
}
  
.search-body {
    background-color: #fff;
    padding: 20px;
    height: calc(100vh - 60px); /* Adjust this value based on the height of the search bar */
    overflow-y: auto;
}
