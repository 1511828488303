@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* Comment Container */
.comment {
  display: flex;
  align-items: center;
  margin: 10px auto; /* Center align within the post */
  max-width: 600px; /* Match the post-card width */
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease-in-out;
}

.comment:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Profile Image */
.comment .profile-image {
  width: 40px; /* Match the user image size in post-card */
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

/* Comment Details */
.comment .comment-details {
  flex: 1;
}

.comment .user-name {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #333;
}

.comment .comment-text {
  font-size: 14px;
  color: #666;
  margin: 5px 0 0;
  line-height: 1.5;
}

/* Comment Actions */
.comment .comment-actions {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.comment .action-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.comment .action-icon {
  font-size: 18px;
  cursor: pointer;
  color: #666;
}

.comment .action-icon:hover {
  color: #007bff;
}

.comment .action-count {
  font-size: 14px;
  color: #666;
}

/* Responsive Design */
@media (max-width: 600px) {
  .comment {
    padding: 10px;
  }

  .comment .profile-image {
    width: 30px; /* Reduce size on smaller screens */
    height: 30px;
  }

  .comment .user-name {
    font-size: 14px;
  }

  .comment .comment-text {
    font-size: 12px;
  }

  .comment .action-icon {
    font-size: 16px;
  }

  .comment .action-count {
    font-size: 12px;
  }
}
