/* Import Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.user-profile {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
}

.user-photo {
  margin-right: 10px;
}

.user-photo img {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.user-details {
  flex: 1;
}

.user-details h2 {
  font-size: 1.2em;
  margin: 0;
}

.user-details p {
  margin: 0;
  color: #777;
}

.follow-button {
  background-color: #8b39e2;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
}

.follow-button.following {
  background-color: #e74c3c;
}
