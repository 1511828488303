/* Import Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
    margin: 0;
    font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
    background-color: #F8F1FF;
}

.terms-container {
    width: 100%;
    background-color: #F8F1FF;
}

.nav-bar {
    display: flex;
    align-items: center;
    background-color: #Fff;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
}

.back-icon {
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.nav-bar h1 {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    padding: 10px;
    font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
    margin: 0;
    flex: 1;
    text-align: center;
}

.terms-content {
    padding: 20px;
    background-color: #F8F1FF;

    color: #333;
    line-height: 1.6;
}

.terms-content h2 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 20px;
}

.terms-content h3 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
}

.terms-content ul {
    margin-top: 10px;
    margin-left: 20px;
}

.terms-content p {
    margin-top: 10px;
}

.terms-content a {
    color: #007bff;
    text-decoration: none;
}

.terms-content a:hover {
    text-decoration: underline;
}
