/* Import Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
}

.navbar {
  width: 250px;
  height: 100vh;
  background-color: black;
  color: #fff;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  transition: width 0.3s ease-in-out;
}

.logo {
  padding: 20px;
  text-align: center; /* Center align the logo */
}

.logo-img {
  max-width: 80%; /* Adjust maximum width of the logo */
  height: auto;
  display: block;
  margin: 0 auto; /* Center align horizontally */
}

.nav-links {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  flex-grow: 1; /* Expand to fill remaining space */
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center; /* Center align items horizontally */
  padding: 15px 20px;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease; /* Transition color property */
  font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
}

.nav-item i {
  margin-right: 10px;
}

.nav-item:hover {
  color: #8a2be2; /* Change text color to purple (#8a2be2) on hover */
}

.nav-item.active {
  color: #8a2be2; /* Change text color to purple (#8a2be2) when active */
}

.nav-item:hover,
.nav-item:focus {
  /* Ensure focus also inherits hover styles */
  text-decoration: none;
  outline: none; /* Remove outline on focus */
}

.nav-text {
  margin-left: 10px;
}

.logout-link {
  display: none; /* Hide logout link by default */
}

@media (max-width: 768px) {
  .navbar {
    width: 100%;
    height: auto;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    top: auto;
  }

  .logo {
    display: none; /* Hide logo on smaller screens */
  }

  .nav-links {
    flex-direction: row;
    justify-content: space-around;
    align-items: center; /* Center align items vertically */
    width: 100%;
    padding: 10px 0; /* Adjust padding as needed */
  }

  .nav-item {
    flex: 1;
    text-align: center; /* Center align items in row */
    padding: 10px 0;
  }

  .nav-text {
    display: none; /* Hide text on smaller screens */
  }
}
