@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
    font-family: 'Poppins', sans-serif;
  }
  
  .comment-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
  }
  
  .top-nav {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
  }
  
  .back-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .back-button .react-icons {
    margin-right: 8px;
  }
  
  .nav-title {
    flex-grow: 1;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
  
  .body {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  .bottom-bar {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #fff;
  }
  
  .edit-text {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .send-button {
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #8b39e2;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .send-button .react-icons {
    margin-left: 8px;
  }
  
  @media (max-width: 600px) {
    .nav-title {
      font-size: 16px;
      margin-right: 40px;
    }
  
    .edit-text {
      padding: 8px;
    }
  
    .send-button {
      padding: 8px 16px;
    }
  }
  