@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
    font-family: 'Poppins', sans-serif;
  }
  
  .nav-barr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .nav-barr h1 {
    flex-grow: 1;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    font-size: 1.3em;
    font-weight: bolder;
  }

  main{
    font-family: 'Poppins', sans-serif;
  }
  
  .nav-barr .back-icon {
    cursor: pointer;
  }
  
  main {
    padding: 20px;
  }
  