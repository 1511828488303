.container {
  padding: 20px;
}

.username-badge {
  background-color: #8a2be2;
  padding: 10px;
  color: white;
  border-radius: 20px;
  display: inline-block;
  margin-bottom: 20px;
}

.feedback-message {
  margin-bottom: 20px;
}

.answer-textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 20px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.hidden {
  display: none;
}

.dialog-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
  z-index: 1000;
}

.dialog-content h3 {
  margin-bottom: 10px;
}

.playstore-button {
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 10px;
  padding: 5px;
  width: 150px;
  cursor: pointer;
}
