/* Import Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.create-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Poppins', sans-serif; /* Set Poppins font */
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 20px;
}

.nav-title {
  font-size: 24px;
  font-weight: bold;
}

.post-btn {
  background-color: #8b39e2;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.input-field {
  width: 100%;
  height: 100%;
  padding: 18px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}

.bottom-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f1ff;
  padding: 10px;
}

.image-icon, .notification-icon {
  cursor: pointer;
  font-size: 24px;
}

.image-icon i, .notification-icon i {
  color: #444444;
  size: 48px;
}

input[type="file"] {
  display: none;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .bottom-nav {
    margin-bottom: 80px; /* Adjust this value as needed to provide enough space */
  }
}
