/* Import Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
  background-color: #F8F1FF;
}

.top-nav {
  display: flex;
  justify-content: space-between;
  font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
  align-items: center;
  background-color: #ffffff;
  padding: 10px 20px;
}

.nav-title-op {
  font-size: 32px;
  font-weight: bolder;
}

.profile {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
  flex-direction: row;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.profile-pic {
  flex: 1;
  padding: 20px;
  text-align: start;
}

.profile-pic img {
  width: 150px; /* Ensure width and height are equal */
  height: 150px; /* Ensure width and height are equal */
  border-radius: 50%; /* Make it a perfect circle */
  object-fit: cover; /* Ensure the image covers the whole area */
  box-shadow: 1px 1px 20px #8b39e2;
}

.profile-info {
  flex: 2;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.username {
  font-size: 24px;
  margin: 0;
}

.handle {
  font-size: 16px;
  color: #8b39e2;
  margin-top: 5px;
  font-weight: bolder;
}

.bio {
  font-size: 16px;
  color: #333;
  margin-top: 10px;
}

.counts {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.counts span {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #333;
}

.counts .label {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}

.foll-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  width: 100%;
  background-color: #8b39e2;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: start; /* Align to the start of the flex container */
}

.foll-button:hover {
  background-color: #7a32cc;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .counts span:nth-child(3) {
    flex: none;
    font-size: 18px;
  }

  .profile {
    max-width: 600px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .profile-pic img {
    width: 100px; /* Smaller size for mobile */
    height: 100px; /* Smaller size for mobile */
  }

  .foll-button {
    align-self: center;
    width: 100%;
  }
}
