/* App.css */

html, body, #root {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
}

.app-container {
  display: flex;
  min-height: 100vh;
}

.navbar {
  width: 250px; /* Adjust as per your navbar width */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000; /* Ensure it stays on top */
  background-color: #fff; /* Adjust as necessary */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Optional shadow for visual separation */
}

.content-container {
  flex: 1;
  margin-left: 250px; /* Same as navbar width */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent horizontal scrollbar */
}

.main-content {
  flex: 1;
  box-sizing: border-box;
  overflow: auto; /* Allow vertical scrolling if needed */
}

/* Ensure full height for the main content */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .navbar {
    position: static;
    width: 100%;
    height: auto;
    box-shadow: none;
  }

  .content-container {
    margin-left: 0;
  }

}

/* Logo animation */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
