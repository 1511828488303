/* Import Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #F8F1FF;
    font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ensure it takes full viewport height */
    background-color: #F8F1FF;
}

.login-form {
    max-width: 400px;
    width: 90%;
    padding: 20px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}

.login-form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
}

.form-group {
    margin-bottom: 20px;
    position: relative;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
}

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
}

.forgot-password {
    display: block;
    margin-top: 5px;
    font-size: 0.9em;
    text-align: right;
    color: #8b39e2;
    text-decoration: none;
    font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
}

.forgot-password:hover {
    text-decoration: underline;
}

.btn {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s, box-shadow 0.3s;
    font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
}

.btn-primary {
    background-color: #8b39e2;
    color: #fff;
    border: none;
}

.btn-primary:hover {
    background-color: #423350;
    box-shadow: 2px 5px 8px rgba(92, 92, 92, 0.9);
}

.btn-google {
    background-color: #efefef;
    color: black;
    border: none;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.btn-google:hover {
    box-shadow: 2px 5px 8px rgba(92, 92, 92, 0.9);
}

.signup-link {
    text-align: center;
    margin-top: 20px;
    font-family: 'Poppins', Arial, sans-serif; /* Set Poppins font */
}

.signup-link a {
    color: #8b39e2;
    text-decoration: none;
    font-weight: 600;
}

.signup-link a:hover {
    text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .login-container {
        align-items: center;
        height: 100%; /* Maintain full height for vertical centering */
    }

    .login-form {
        max-width: 100%;
    }
}
