.Post {
  margin: 0;
  padding: 0;
}

/* Post Card */
.post-card {
  background-color: #fff;
  margin: 20px auto;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

/* Add styles for user-info */
.post-card .user-info {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between to push the options menu to the end */
  margin-bottom: 10px;
}

.post-card .user-details {
  display: flex;
  align-items: center;
}

.post-card .user-details img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.post-card .user-details h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.post-card .user-details .time {
  color: #999;
  font-size: 12px;
}

.post-card .options-menu {
  cursor: pointer;
}

.post-card .options-menu i {
  font-size: 20px;
  color: #333;
}

.Post a {
  color: inherit; 
  text-decoration: none; 
}

.post-card .post-text {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.post-card img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
}

.post-card .post-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.post-card .post-actions span {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.post-card .post-actions span i {
  margin-right: 5px;
}

nav .install-button {
  margin-left: auto;
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

/* Add media query for responsive design of the "Install the App" button */
@media (max-width: 600px) {
  nav .install-button {
    font-size: 12px;
    padding: 6px 12px;
  }
}

/* Media Queries for Responsive Design */
@media (max-width: 600px) {
  nav img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  nav h1 {
    font-size: 18px;
  }

  .post-card {
    padding: 15px;
  }

  .post-card .user-info img {
    width: 30px;
    height: 30px;
  }

  .post-card .user-details h3 {
    font-size: 14px;
  }

  .post-card .time {
    font-size: 10px;
  }

  .post-card .post-text {
    font-size: 14px;
  }

  .post-card .post-actions {
    font-size: 12px;
  }

  /* Add styles for the icon and count elements */
  .post-card .post-actions .far {
    margin-right: 5px;
  }

  .post-card .post-actions .like-count,
  .post-card .post-actions .dislike-count,
  .post-card .post-actions .comment-count {
    margin-left: 5px;
  }

  /* Add styles for the responsive layout of the count elements */
  .post-card .post-actions .like-count,
  .post-card .post-actions .dislike-count,
  .post-card .post-actions .comment-count {
    font-size: 10px;
  }
}
